<template>
    <div class="common_sub_container">
        <h2>Holidays</h2>
        <section>
            <div class="two_tabs">
                <a @click="showTab1 = true, showTab2 = false" v-bind:class="{ active: showTab1 }" class="tab_buttons">ADD</a>
                <a @click="showTab2 = true, showTab1 = false" v-bind:class="{ active: showTab2 }"
                    class="tab_buttons">VIEW</a>
            </div>
            <HolidayAdd v-if="showTab1" @redirect="showTab2 = true, showTab1 = false" />
            <HolidayView v-if="showTab2" />
        </section>
    </div>
</template>
<script>
import HolidayAdd from "./Holiday_Add.vue";
import HolidayView from "./Holiday_View.vue";
export default {
    name: "x",
    components: {
        HolidayAdd,
        HolidayView
    },
    created() {
        this.$emit("breadCrumbData", ["Master Data", "Holidays"]);
    },
    data() {
        return {
            showTab1: true,
            showTab2: false
        };
    }
};
</script>
<style scoped></style>
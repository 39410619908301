<template>
    <div class="scoped_cover_div">
        <div class="jobs_table sp">
            <div class>
                <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
                    <section>
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                            slim>
                                            <div class="label_and_element_wrapper">
                                                <label>Date</label>
                                                <flat-pickr v-model="date" :config="configDate"
                                                    placeholder="Select Date" name="date"
                                                    @on-close="setDate"></flat-pickr>
                                            </div>
                                        </ValidationProvider>
                                    </td>

                                    <td>
                                        <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                            slim>
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Holiday title
                                                    <span>*</span>
                                                </label>
                                                <input type="text" placeholder="name"
                                                    :class="errors[0] ? 'warning-border' : ''" v-model="name"
                                                    :maxlength="max2" />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="rowspanned_4_chk">
                                        <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                            slim>
                                            <div class="label_and_element_wrapper">
                                                <div class="chk_group" style="height: 130px;overflow:hidden;">
                                                    <label :class="errors[0] ? 'warning-border' : ''"
                                                        style="background: none;">
                                                        <input type="radio" v-model="type" name="type" value="country">
                                                        <span style="font-size: 14px;">Island wide ?</span>
                                                    </label>

                                                    <label :class="errors[0] ? 'warning-border' : ''"
                                                        style="background: none;">
                                                        <input type="radio" v-model="type" name="type" value="state" />
                                                        <span style="font-size: 14px;">Within state ?</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>

                                <tr v-if="type == 'state'">
                                    <td class="rowspanned_4_chk">
                                        <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                            slim>
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    State
                                                    <span>*</span>
                                                </label>
                                                <div class="chk_group">
                                                    <label v-for="(state, i) in states" :key="'state' + i"
                                                        :class="errors[0] ? 'warning-border' : ''">
                                                        <input type="checkbox" v-model="state_ids"
                                                            :value="state.id" />
                                                        <span>{{ state.name }}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </table>


                            <table>
                                <tr>
                                    <td>
                                        <ValidationProvider name="description" rules="required" v-slot="{ errors }"
                                            slim>
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Description
                                                    <span>*</span>
                                                </label>
                                                <textarea placeholder="Type Here Description"
                                                    :class="errors[0] ? 'warning-border' : ''"
                                                    v-model="description"></textarea>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </section>
                </ValidationObserver>

                <section class="centered">
                    <a class="link_bt bt_save" @click="submit">Save</a>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import _ from 'lodash'
import "flatpickr/dist/flatpickr.css";

export default {
    name: "AddHoliday",
    components: {
        flatPickr,
    },
    data() {
        return {
            max: 70,
            max2: 30,
            configDate: {
                altInput: true,
                noCalendar: false,
                format: "d-m-Y",
                altFormat: "d-m-Y",
            },

            states: [],

            date: "",
            type: "country",
            description: "",
            state_ids: [],
            name: ""

        }
    },
    created() {
        this.fetchStates();
    },
    methods: {
        fetchStates() {
            this.axios.get("/api/state")
                .then(response => {
                    console.log(response.data);
                    this.states = response.data;
                })
                .catch(error => {
                    this.toast.error();
                });
        },

        setDate() {

        },
        submit() {
            let formData = new FormData();
            formData.append("date", this.date);
            formData.append("name", this.name);
            formData.append("type", this.type);
            formData.append("description", this.description);

            this.state_ids.forEach(element => {
                formData.append("state_ids[]", element);
            });

            this.$refs.observer.validate();
            this.axios
                .post("/api/holiday-calender/", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(response => {
                    this.toast.success(response.data.msg);
                    this.$emit('redirect');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
<style>
.rowspanned_4_chk {
    width: 40%;
    overflow-x: scroll;
}

.rowspanned_4_chk .chk_group {
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    height: 320px;
    overflow-y: scroll;
}

.rowspanned_4_chk .chk_group label {
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
    background: #efefef;
}

.rowspanned_4_chk .chk_group label:last-child {
    margin-bottom: 0;
}

.rowspanned_4_chk .chk_group label span {
    display: inline-block;
    color: #333;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    position: relative;
    top: -3px;
}

.rowspanned_4_chk .chk_group label input[type="checkbox"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}
</style>
<style scoped>
.scoped_cover_div {
    margin: 0;
}

.scoped_cover_div section {
    padding: 0;
}

.scoped_cover_div .form_section1 {
    margin-top: 0;
}

.jobs_table.sp table {
    width: 100%;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
    border: 0;
    padding: 0 0 20px 0;
    vertical-align: top;
}

.jobs_table.sp table tr td {
    width: 33%;
}

.user_profile_upload {
    display: block;
    border: 0;
    height: 40px;
    width: 200px;
    background: #004a7c;
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 30px;
}

.user_profile_upload input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.user_profile_upload span {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: normal;
}

._sur_chk {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}

._sur_container {
    display: block;
    width: 100%;
    overflow-x: auto;
    background: #eee;
}

._sur_flex_container {
    display: flex;
    padding: 10px 0;
    width: 1200px;
    min-width: 1400px;
    justify-content: space-between;
}

._sur_flex_boxes {
    width: auto;
    min-width: 80px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    margin-right: 2px;


}

._sur_newdiv {
    display: flex;
    align-items: center;
}

._sur_newdiv label {
    padding-top: 12px;
}

._sur_flex_boxes input[type="checkbox"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}

._sur_flex_boxes input[type="radio"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}
</style>
<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                   <div class="label_and_element_wrapper">
                     <label>
                      Carrier Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="name" :maxlength="max2"/>
                    </div>
                  </ValidationProvider>
                </td>

                <td>
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                     <div class="label_and_element_wrapper">
                      <label>
                      Carrier Code
                      <span>*</span>
                      </label>
                      <input type="text" placeholder="Carrier code Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="carrier_code" :maxlength="max2"/>
                     </div>
                  </ValidationProvider>
                </td>
              </tr>


              <tr>
                <td>
                 
                   <div class="label_and_element_wrapper">
                    <label>
                    Transit Type
                    
                    </label>
                    <select  v-model="transit_type" >
                    <option value="" disabled>Select Transit Type</option>
                    <option value = "postcodes">Post Codes</option>
                    <option value = "city">City</option> 
                    <option value = "state">State</option>
                    <option value = "country">Country</option>
                    </select>
                   </div>
                  
                </td>


                <td>
                <div class="label_and_element_wrapper">
                <label>
                 Default Transit Days
                </label>
                <input type="text" placeholder=" Default Transit Days" v-model="default_transit_days" :maxlength="max2"/>
                </div>
                </td> 
              </tr>
                     

              <tr>
                <td class="rowspanned_4_chk">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Carrier Service Name
                  <span>*</span>
                  </label>
                  <div class="chk_group" >
                  <label v-for="(service,i) in services" :key="'service'+i" :class="errors[0] ? 'warning-border':''">
                  <input type="checkbox" v-model="carrier_service_name" :value="service.id"/>
                  <span>{{service.name}}</span>
                  </label>
                  </div>
                  </div>
                  </ValidationProvider>
                </td>

                <td class="rowspanned_4_chk">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Carrier Service Type
                  <span>*</span>
                  </label>
                  <div class="chk_group" >
                  <label v-for="(type,i) in servicetypes" :key="'type'+i" :class="errors[0] ? 'warning-border':''">
                  <input type="checkbox" v-model="carrier_service_type" :value="type.id"/>
                  <span>{{type.service_type}}</span>  
                  </label>
                  </div>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>


              <tr>
                <td class="rowspanned_4_chk">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Mode of Dispatch
                  <span>*</span>
                  </label>
                  <div class="chk_group" >
                  <label v-for="(dispatch,i) in dispatches" :key="'dispatch'+i" :class="errors[0] ? 'warning-border':''">
                  <input type="checkbox" v-model="mode_of_dispatch" :value="dispatch.id"/>
                  <span>{{dispatch.name}}</span>
                  </label>
                  </div>
                  </div>
                  </ValidationProvider>
                </td>

                <td class="rowspanned_4_chk">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Type of Goods
                  <span>*</span>
                  </label>
                  <div class="chk_group" >
                  <label v-for="(good,i) in goods" :key="'good'+i" :class="errors[0] ? 'warning-border':''">
                  <input type="checkbox" v-model="type_of_good" :value="good.id"/>
                  <span>{{good.type}}</span>
                  </label>
                  </div>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>


              <tr>
                <td class="rowspanned_4_chk">
                  <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <div class="chk_group" style="height: 73px;overflow:hidden;" >
                    <label :class="errors[0] ? 'warning-border':''" style="background: none;">
                    <input type="checkbox"  v-model="is_generate_carrier_slip_hub"/>
                    <span style="font-size: 14px;"> Generate Carrier Slip from Hub</span>
                    </label>
                    </div>
                  </div>
                  </ValidationProvider>
                </td>


                  <td class="rowspanned_4_chk">
                    <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper"> 
                    <div class="chk_group" style="height: 73px;overflow:hidden;" >
                    <label :class="errors[0] ? 'warning-border':''" style="background: none;">
                    <input type="checkbox" v-model=is_dispatch_manifest_hub />
                    <span style="font-size: 14px;">Dispatch Manifest from Hub</span>
                    </label>
                    </div>
                    </div>
                    </ValidationProvider>
                  </td>
                
              </tr>

              <tr>
                  <td>                 
                  <div class="label_and_element_wrapper">
                  <label>
                  Dg
                  </label>
                  <input class="_sur_chk" type="checkbox" name="dg" v-model="is_dg"  true-value="1" false-value="0"  />
                  <label style="right: -69px;position: relative;top: -60px;">
                  Dg Information
                  </label>
                  <input type="text" placeholder="Enter Dg Information" v-model="dg_information"   :disabled="is_dg==0"  :maxlength="max2" style="width: 90%;float: right;top: -65px;position: relative;">
                  </div>
                   </td>


                  <td>
                    <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                     <div class="label_and_element_wrapper">
                      <label>
                      Carrier Account
                      <span>*</span>
                      </label>
                      <input type="text" placeholder="Name Character Limit 30"  :class="errors[0] ? 'warning-border':''" v-model="carrier_account" :maxlength="max2"/>
                     </div>
                    </ValidationProvider>
                  </td>
              </tr>
              <tr>
                <td style="display: flex;">
                  <div class="_sur_flex_boxes">
                    <div class="label_and_element_wrapper" >
                      <label>
                        Inbound ?
                      </label>
                      <input name="available" type="checkbox" v-model="inbound_provider" true-value="1" false-value="0"  /> 
                    </div>
                  </div> 
               
                  <div class="_sur_flex_boxes">
                    <div class="label_and_element_wrapper" >
                      <label>
                        Outbound ?
                      </label>
                      <input name="available" type="checkbox" v-model="outbound_provider" true-value="1" false-value="0"  /> 
                    </div>
                  </div>

                  <div class="_sur_flex_boxes">
                    <div class="label_and_element_wrapper" >
                      <label>
                        For user?
                      </label>
                      <input name="available" type="checkbox" v-model="is_user_selection_need" true-value="1" false-value="0"  /> 
                    </div>
                  </div>

                </td>
              </tr>
      
              <!-- <tr>
               


                 <td>
                <div class="label_and_element_wrapper">
                <label>
               Preferred 
                </label>
                <input name="available" type="checkbox" v-model="is_carrier_preference" true-value="1" false-value="0"/>
                </div>

                <div class="label_and_element_wrapper" style="margin-top: -70px;margin-left: 112px;">
                <label style="right: 39px;position: relative;top:1px;">
                 Rating
                  <span>*</span>
                </label>
                </div>

                <div class="newdiv" style="margin-left: 88px;margin-top: -10px;position: relative;">
                 
                <input type="text" placeholder="Enter Rating" v-model="sell_rate_margin" style="width: 15%;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                </div>
                
                <div class="label_and_element_wrapper" style="margin-top: -77px;margin-left: 162px;position: relative;left: 3%;">
                <label>
                Duty Paid
                </label>
                  <input name="available" type="checkbox" v-model="is_duty_paid_by_shipper" true-value="1" false-value="0" style="position: relative;top: 0%;"/> 
                </div>
                  
                <div class="label_and_element_wrapper" style="margin-top: -70px;margin-left: 233px;position: relative;left: 6%;">
                <label>
                 Scheduled Pickup 
                </label>
                  <input name="available" type="checkbox" v-model="is_scheduled_pickup_api" true-value="1" false-value="0" style="position: relative;top: 0%;"/> 
                </div>

                <div class="label_and_element_wrapper" style="margin-top: -68px;float:right;">
                <label>
                  Generate Label Api
                </label>
            
                 <input name="available" type="checkbox" v-model="is_generate_label_api" true-value="1" false-value="0" style="position: relative;
                top: 0%;" /> 
                </div>

              </td>

              <td>
                <div class="label_and_element_wrapper">
                <label>
                  Dispatched Api
               
                </label>
                <input name="available" type="checkbox" v-model="is_available" true-value="1" false-value="0"/>
                </div>

                <div class="label_and_element_wrapper" style="margin-top: -71px;margin-left: 112px;">
                <label>
                Markup Margin 
                <span>*</span>
                </label>
                
                </div>

                <div class="newdiv" style="margin-left: 130px;margin-top: -10px;position: relative;">
                <label class="radio-inline">
                Percentage
                </label> 
                <input type="radio" name="optradio"   @change="onChangeMarkupMargin($event)" value="0" style="margin: 0 15px;position: relative;"> 
                <input type="text" placeholder="Enter" v-model="markup_margin_per" :disabled="markup_margin_type!='per'" style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">

                <label class="radio-inline" style="margin: 0 0 0 15px;">
                Fixed
                </label> 
                <input type="radio" name="optradio"   @change="onChangeMarkupMargin($event)" value="1" style="margin: 0 15px;position: relative;"> 
                <input type="text" placeholder="Enter" v-model="markup_margin_fix" :disabled="markup_margin_type!='fix'" style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                </div>
              </td>     
            </tr> -->
            </table>
            
              <div class="_sur_container">
                  <div class="_sur_flex_container">
                      <div class="_sur_flex_boxes">
                          <div class="label_and_element_wrapper">
                          <label>
                          Preferred 
                          </label>
                          <input name="available" type="checkbox" v-model="is_carrier_preference" true-value="1" false-value="0"/>
                          </div>
                      </div>  
                      <div class="_sur_flex_boxes">

                              <div class="label_and_element_wrapper">
                              <label >
                                Rating
                                <span>*</span>
                              </label>
                              <input type="text" placeholder="Enter Rating" v-model="sell_rate_margin" >
                              </div>

                      </div>  
                      <div class="_sur_flex_boxes">
                          <div class="label_and_element_wrapper">
                            <label>
                            Receiver Duty Paid
                            </label>
                            <input name="available" type="checkbox" v-model="is_duty_paid_by_shipper" true-value="1" false-value="0" style=""/> 
                          </div>


                      </div>  
                      <div class="_sur_flex_boxes">
                          <div class="label_and_element_wrapper" >
                            <label>
                              Regular Pickup
                            </label>
                            <input name="available" type="checkbox" v-model="is_scheduled_pickup_api" true-value="1" false-value="0" style=""/> 
                          </div>

                      </div>  
                      <div class="_sur_flex_boxes">

                          <div class="label_and_element_wrapper" >
                            <label>
                              Generate Label Api
                            </label>
                            <input name="available" type="checkbox" v-model="is_generate_label_api" true-value="1" false-value="0"  /> 
                          </div>

                      </div>  
                      <div class="_sur_flex_boxes">
                          <div class="label_and_element_wrapper">
                          <label>
                            Dispatched Api
                          <!-- Is API Available?  -->
                          </label>
                          <input name="available" type="checkbox" v-model="is_available" true-value="1" false-value="0"/>
                          </div>

                      </div>  
                      <div class="_sur_flex_boxes">

                          <div class="label_and_element_wrapper" style="">
                                  <label>
                                  Markup Margin 
                                  <span>*</span>
                                  </label>
                                  <div class="newdiv _sur_newdiv" >
                                          <label class="radio-inline">
                                          Percentage
                                          </label> 
                                          <input type="radio" name="optradio"   @change="onChangeMarkupMargin($event)" value="0" style="margin: 0 15px;position: relative;"> 
                                          <input type="text" placeholder="Enter" v-model="markup_margin_per" :disabled="markup_margin_type!='per'" style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">

                                          <label class="radio-inline" style="margin: 0 0 0 15px;">
                                          Fixed
                                          </label> 
                                          <input type="radio" name="optradio"   @change="onChangeMarkupMargin($event)" value="1" style="margin: 0 15px;position: relative;"> 
                                          <input type="text" placeholder="Enter" v-model="markup_margin_fix" :disabled="markup_margin_type!='fix'" style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                                  </div>
                          </div>

                      </div>  
                  </div> 
            </div>
               
             
            <table>
            <tr>
              <td>
              <ValidationProvider name="description" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
              <label>
              Description
              <span>*</span>
              </label>
              <textarea placeholder="Type Here Carrier Description"  :class="errors[0] ? 'warning-border':''" v-model="description" ></textarea>
              </div>
              </ValidationProvider>
              </td>
            </tr>
            
            </table>

            <table>
              <tr>
                <td>
                  <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Upload Logo
                  <span>*</span>
                  </label>
                  <label class="user_profile_upload ">
                  <span>Choose a File</span>
                  <span style="float: right; color: #767272;font-size: 14px;"> Size 150*75</span>
                  <input type="file"  ref="logo" @change="uploadFile" accept="image/*" :class="errors[0] ? 'warning-border':''"/>
                  <span v-if= "logo != '' ">
                  {{logoname}}
                  </span>
                  </label>
                  </div>
                  </ValidationProvider> 
                  </td>


                <td>
                  <ValidationProvider name="terms" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Upload Special Notes
                 
                  </label>
                  <label class="user_profile_upload ">
                  <span>Choose a File</span>
                  <span style="float: right; color: #767272;font-size: 14px;">PDF Format Recommended</span>
                  <input type="file"  ref="sla" @change="uploadSLADocument" accept=".pdf" :class="errors[0] ? 'warning-border':''"/>
                  <span v-if= "sla != '' ">
                  {{sla_name}}
                  </span>
                  </label>
                  </div>
                  </ValidationProvider>
                </td>


                <td>
                  <ValidationProvider name="logo" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                  <label>
                  Upload Terms & Conditions
                 
                  </label>
                  <label class="user_profile_upload ">
                  <span>Choose a File</span>
                  <span style="float: right; color: #767272;font-size: 14px;">PDF Format Recommended</span>
                  <input type="file"  ref="terms" @change="uploadCarrierContract" accept=".pdf" :class="errors[0] ? 'warning-border':''"/>
                  <span v-if= "terms!= '' ">
                  {{terms_name}}
                  </span>
                  </label>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
               
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Carriers",
  data(){
    return {
      max:70,
      max2:30,
      dispatches:[],
      servicetypes:[],
      services:[],
      goods:[],
      name : "",
      mode_of_dispatch: [],
      carrier_code:"",
      logo:"",
      description:"",
      // rate_card:"",
      logoname:"",
      is_available :0,
      ratecardname:"",
      is_dg:0,
      dg_information:"",
      is_carrier_preference:0,
      sell_rate_margin:"",
      transit_type:"",
      markup_margin:"",
      markup_margin_type:"",
      markup_margin_per:"",
      markup_margin_fix:"",
      terms:"",
      terms_name:"",
      sla:"",
      sla_name:"",
      default_transit_days:"",
      is_generate_carrier_slip_hub:0,
      is_dispatch_manifest_hub:0,
      carrier_account:"",
      is_scheduled_pickup_api:0,
      is_generate_label_api:0,
      inbound_provider:0,
      outbound_provider:0,
      is_user_selection_need:0,
      is_duty_paid_by_shipper:0,
      carrier_service_name:[],
      carrier_service_type:[],
      type_of_good:[],

    }
  },
  created() {
    this.fetchModeOfDispatch();
    this.fetchCarrierServiceType();
    this.fetchServices();
    this.fetchTypeofGood();

  },
  methods: {
    fetchModeOfDispatch(){
      this.axios.get("/api/dispatch/")
      .then(response => {
          this.dispatches = response.data.mode_of_dispatch;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

    fetchTypeofGood(){
      this.axios.get("/api/goods/")
      .then(response => {
          this.goods = response.data.goods;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

     fetchServices(){
      this.axios.get("/api/service/")
      .then(response => {
          this.services = response.data.services;
      })
      .catch(error =>{
          this.toast.error();
      });
    },


    fetchCarrierServiceType(){
      this.axios.get("/api/common/servicetype")
      .then(response => {
          this.servicetypes = response.data.servicetype;
          
      })
      .catch(error =>{
          this.toast.error();
      });
    },
     

    onChangeMarkupMargin(event) {

    //console.log(optionText);
     var optionText = event.target.value;
      if(optionText == 0)
      {
        this.markup_margin_type ="per";
      }
      if(optionText == 1)
      {
        this.markup_margin_type ="fix";
      }

    },

    uploadFile () {
      // if(this.$refs["logo"].files.length>0)
      // this.logo = this.$refs["logo"].files;
      //  if(this.logo){
      //   this.logoname = this.logo.name;
       // }
      this.logo = this.$refs["logo"].files[0];
      this.logoname = this.logo.name;
    
    },

    uploadSLADocument () {
     
      this.sla = this.$refs["sla"].files[0];
      this.sla_name = this.sla.name;
      

    },
    uploadCarrierContract()
    {
       this.terms = this.$refs["terms"].files[0];
           this.terms_name = this.terms.name;
    },
    

submit(){

      this.markup_margin = this.markup_margin_per != ''? this.markup_margin_per : this.markup_margin_fix;
       this.default_transit_days = this.default_transit_days == ''? 0 : this.default_transit_days;
      
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("carrier_code", this.carrier_code);
      formData.append("logo", this.logo);
      formData.append("terms", this.terms);
      formData.append("description", this.description);
      formData.append("is_available", this.is_available);
      formData.append("markup_margin", this.markup_margin);
      formData.append("markup_margin_type", this.markup_margin_type);
      formData.append("is_dg", this.is_dg);
      formData.append("dg_information", this.dg_information);
      formData.append("is_carrier_preference", this.is_carrier_preference);      
      formData.append("sell_rate_margin", this.sell_rate_margin);
      formData.append("sla", this.sla);
      formData.append("transit_type", this.transit_type);
      formData.append("is_generate_carrier_slip_hub", this.is_generate_carrier_slip_hub);      
      formData.append("is_dispatch_manifest_hub", this.is_dispatch_manifest_hub);
      formData.append("carrier_account", this.carrier_account);
      formData.append("default_transit_days", this.default_transit_days);
      formData.append("is_scheduled_pickup_api", this.is_scheduled_pickup_api);
      formData.append("is_generate_label_api", this.is_generate_label_api);
      formData.append("inbound_provider", this.inbound_provider);
      formData.append("outbound_provider", this.outbound_provider);
      formData.append("is_user_selection_need", this.is_user_selection_need);
      formData.append("is_duty_paid_by_shipper", this.is_duty_paid_by_shipper);
      
      this.mode_of_dispatch.forEach(element =>{
        formData.append("mode_of_dispatch[]", element);
      });
      this.carrier_service_name.forEach(element =>{
        formData.append("carrier_service_name[]", element);
      });
      this.carrier_service_type.forEach(element =>{
        formData.append("carrier_service_type[]", element);
      });

      this.type_of_good.forEach(element =>{
        formData.append("type_of_good[]", element);
      });

      console.log(formData);
    
      this.$refs.observer.validate();
        this.axios
        .post("/api/provider/", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style>
.rowspanned_4_chk {
  width: 40%;
  overflow-x: scroll;
}
.rowspanned_4_chk .chk_group {
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
  height: 320px;
  overflow-y: scroll;
}
.rowspanned_4_chk .chk_group label {
  height: 40px;
  padding: 5px;
  margin-bottom: 10px;
  background: #efefef;
}
.rowspanned_4_chk .chk_group label:last-child {
  margin-bottom: 0;
}
.rowspanned_4_chk .chk_group label span {
  display: inline-block;
  color: #333;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  position: relative;
  top: -3px;
}
.rowspanned_4_chk .chk_group label input[type="checkbox"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  position: relative;
  top: 2px;
}

</style>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 200px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  margin-bottom:30px;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
}
._sur_chk{
  display: inline-block;
width: 20px;
height: 20px;
border: 1px solid #333;
position: relative;
top: 2px;
}

._sur_container{
  display:block;  width:100%;
  overflow-x:auto;
  background:#eee;
}

._sur_flex_container{
  display:flex; padding:10px 0; width:1200px;
  min-width:1400px;
   justify-content: space-between;
}
._sur_flex_boxes{
  width:auto; min-width:80px;min-height: 50px;
  display: flex; flex-direction: column; margin-right: 2px;
 
  
}

._sur_newdiv{display: flex;  align-items: center;}
._sur_newdiv label{padding-top: 12px;}
._sur_flex_boxes input[type="checkbox"]{
  display: inline-block;
width: 20px;
height: 20px;
border: 1px solid #333;
position: relative;
top: 2px;
}

._sur_flex_boxes input[type="radio"]{
  display: inline-block;
width: 20px;
height: 20px;
border: 1px solid #333;
position: relative;
top: 2px;
}
</style>
<template>
    <div class="popup_sub_container">
        <h2>VIEW / EDIT Holiday </h2>
        <i class="closeme" @click="closeModal">X</i>
        <section>
            <div class="jobs_table sp">
                <div class>
                    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
                        <section>
                            <div class="form_section1">
                                <table>
                                    <tr>
                                        <td>
                                            <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                                slim>
                                                <div class="label_and_element_wrapper">
                                                    <label>Date</label>
                                                    <flat-pickr v-model="date" :config="configDate"
                                                        placeholder="Select Date" name="date"
                                                        @on-close="setDate"></flat-pickr>
                                                </div>
                                            </ValidationProvider>
                                        </td>

                                        <td>
                                            <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                                slim>
                                                <div class="label_and_element_wrapper">
                                                    <label>
                                                        Holiday title
                                                        <span>*</span>
                                                    </label>
                                                    <input type="text" placeholder="name"
                                                        :class="errors[0] ? 'warning-border' : ''" v-model="name"
                                                        :maxlength="max2" />
                                                </div>
                                            </ValidationProvider>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="rowspanned_4_chk">
                                            <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                                slim>
                                                <div class="label_and_element_wrapper">
                                                    <div class="chk_group" style="height: 130px;overflow:hidden;">
                                                        <label :class="errors[0] ? 'warning-border' : ''"
                                                            style="background: none;">
                                                            <input type="radio" v-model="type" name="type"
                                                                value="country">
                                                            <span style="font-size: 14px;">Island wide ?</span>
                                                        </label>

                                                        <label :class="errors[0] ? 'warning-border' : ''"
                                                            style="background: none;">
                                                            <input type="radio" v-model="type" name="type"
                                                                value="state" />
                                                            <span style="font-size: 14px;">Within state ?</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </td>
                                    </tr>

                                    <tr v-if="type == 'state'">
                                        <td class="rowspanned_4_chk">
                                            <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
                                                slim>
                                                <div class="label_and_element_wrapper">
                                                    <label>
                                                        State
                                                        <span>*</span>
                                                    </label>
                                                    <div class="chk_group">
                                                        <label v-for="(state, i) in states" :key="'state' + i"
                                                            :class="errors[0] ? 'warning-border' : ''">
                                                            <input type="checkbox" v-model="state_ids"
                                                                :value="state.id" />
                                                            <span>{{ state.name }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </td>
                                    </tr>
                                </table>

                                <table>
                                    <tr>
                                        <td>
                                            <ValidationProvider name="description" rules="required" v-slot="{ errors }"
                                                slim>
                                                <div class="label_and_element_wrapper">
                                                    <label>
                                                        Description
                                                        <span>*</span>
                                                    </label>
                                                    <textarea placeholder="Type Here Description"
                                                        :class="errors[0] ? 'warning-border' : ''"
                                                        v-model="description"></textarea>
                                                </div>
                                            </ValidationProvider>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </section>
                    </ValidationObserver>

                    <section class="centered">
                        <a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
                        <a class="link_bt bt_save" @click="updateHoliday" :disabled="update_btn.disabled"
                            v-if="is_editable">{{ update_btn.value }}</a>
                    </section>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment-timezone";

export default {
    name: "EditHoliday",
    components: {
        flatPickr,
    },
    created() {
        this.fetchStates();
        this.fetchHoliday();
    },
    data() {
        return {
            max: 70,
            max2: 30,
            configDate: {
                altInput: true,
                noCalendar: false,
                format: "d-m-Y",
                altFormat: "d-m-Y",
            },

            states: [],
            update_btn: {
                disabled: false,
                value: "UPDATE"
            },
            date: "",
            type: "country",
            description: "",
            state_ids: [],
            name: ""
        };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        fetchHoliday() {
            this.axios.get("api/holiday-calender/" + this.holiday_id)
                // .then(response => {
                .then(async (response) => {
                    console.log(response.data)
                    this.date = response.data.date;
                    this.name = response.data.name;
                    this.description = response.data.description;
                    this.type = response.data.type;

                    // this.state_ids = 


                    this.state_ids = response.data.holiday_states.map(holidayStatus => {
                        return holidayStatus.state_id;
                    });

                    console.log(this.state_ids)
                })
                .catch(error => {
                    console.log(error);
                })
        },
        fetchStates() {
            this.axios.get("/api/state")
                .then(response => {
                    console.log(response.data);
                    this.states = response.data;
                })
                .catch(error => {
                    this.toast.error();
                });
        },

        setDate() {

        },
        updateHoliday(carrier) {
            let formData = new FormData();
            formData.append("date", this.date);
            formData.append("name", this.name);
            formData.append("type", this.type);
            formData.append("description", this.description);

            this.state_ids.forEach(element => {
                formData.append("state_ids[]", element);
            });

            this.$refs.observer.validate();
            this.axios
                .post("/api/holiday-calender/" + this.holiday_id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(response => {
                    this.toast.success(response.data.msg);
                    this.$emit('redirect');
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    props: {
        holiday_id: Number,
        is_editable: {
            type: Boolean,
            default: true
        }
    }
};
</script>
<style lang="scss">
.popup_sub_container {
    padding: 20px;
    width: 100%;
    margin: auto;

    background: #fff;
}

.popup_sub_container>section {
    padding: 20px 0 0 0;
}

h3.sub_head {
    font-size: 20px;
    font-weight: 600;
    padding: 0;
}

.preferred_hide {
    display: none;
}

.chk_prefered {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
    display: block;
}

.title_image {
    display: block;
    margin: auto;
}

.form_section1 {
    width: 100%;
    height: auto;
    padding: 20px;
    background: #ececec;
    margin: 20px 0;
}

i.closeme {
    width: 30px;
    height: 30px;
    background: #000;
    color: #06A5ED;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    display: block;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
    border: 0;
}

.jobs_table table tr td {
    padding: 0;
    border: 0;
}

.user_profile_upload {
    display: block;
    border: 0;
    height: 40px;
    width: 200px;
    background: #004a7c;
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 30px;
}

.user_profile_upload input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.user_profile_upload span {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: normal;
}
</style>

<style>
.rowspanned_4_chk {
    width: 40%;
    overflow-x: scroll;
}

.rowspanned_4_chk .chk_group {
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    height: 320px;
    overflow-y: scroll;
}

.rowspanned_4_chk .chk_group label {
    height: 40px;
    padding: 5px;
    margin-bottom: 10px;
    background: #efefef;
}

.rowspanned_4_chk .chk_group label:last-child {
    margin-bottom: 0;
}

.rowspanned_4_chk .chk_group label span {
    display: inline-block;
    color: #333;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    position: relative;
    top: -3px;
}

.rowspanned_4_chk .chk_group label input[type="checkbox"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}
</style>
<style scoped>
.scoped_cover_div {
    margin: 0;
}

.scoped_cover_div section {
    padding: 0;
}

.scoped_cover_div .form_section1 {
    margin-top: 0;
}

.jobs_table.sp table {
    width: 100%;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
    border: 0;
    padding: 0 0 20px 0;
    vertical-align: top;
}

.jobs_table.sp table tr td {
    width: 33%;
}

.user_profile_upload {
    display: block;
    border: 0;
    height: 40px;
    width: 200px;
    background: #004a7c;
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 30px;
}

.user_profile_upload input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.user_profile_upload span {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-weight: normal;
}

._sur_chk {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}

._sur_container {
    display: block;
    width: 100%;
    overflow-x: auto;
    background: #eee;
}

._sur_flex_container {
    display: flex;
    padding: 10px 0;
    width: 1200px;
    min-width: 1400px;
    justify-content: space-between;
}

._sur_flex_boxes {
    width: auto;
    min-width: 80px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    margin-right: 2px;


}

._sur_newdiv {
    display: flex;
    align-items: center;
}

._sur_newdiv label {
    padding-top: 12px;
}

._sur_flex_boxes input[type="checkbox"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}

._sur_flex_boxes input[type="radio"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #333;
    position: relative;
    top: 2px;
}
</style>
<template>
    <div class="scoped_cover_div">
        <div class="jobs_table sp2">
            <table>
                <tr>
                    <th>DATE</th>
                    <th>TITLE</th>
                    <!-- <th>DESCRIPTION</th> -->
                    <th>TYPE</th>
                    <th>STATES</th>
                    <th>Action</th>
                </tr>
                <tr v-for="(holiday, i) in holidays" :key="i">
                    <td>
                        <div class="label_and_element_wrapper">
                            <input type="text" placeholder="Enter Character Limit 30" :maxlength="max2"
                                :disabled="!holiday.is_edit_mode" v-model="holiday.date" />
                        </div>
                    </td>
                    <td>
                        <div class="label_and_element_wrapper" style="width: 100px;">
                            <input type="text" v-model="holiday.name" placeholder="Transit Days" :maxlength="max2"
                                :disabled="!holiday.is_edit_mode" />
                        </div>
                    </td>
                    <!-- <td>
                        <div class="label_and_element_wrapper" style="width: 100px;">
                            <input type="text" v-model="holiday.description" placeholder="Transit Days"
                                :maxlength="max2" :disabled="!holiday.is_edit_mode" />
                        </div>
                    </td> -->
                    <td>
                        <div v-if="holiday.type == 'state'">
                            {{ 'State' }}
                        </div>
                        <div v-else>
                            {{ 'Whole Country' }}
                        </div>
                    </td>
                    <td>
                        <div v-if="holiday.holiday_states">
                            <table>
                                <tbody>
                                    <tr v-for="(holidayState, i) in holiday.holiday_states" :key="'service' + i">
                                        <td>{{ holidayState.state.code }}</td>
                                        <td>{{ holidayState.state.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>

                    <td class>
                        <a class="table_action_btn" title="EDIT"
                            @click="holiday_id = holiday.id, is_editable = true">EDIT</a>
                        <a class="table_action_btn" title="DELETE" @click="deleteHoliday(holiday.id)">DELETE</a>
                    </td>
                </tr>
            </table>
        </div>
        <div class="black-overlay" v-if="holiday_id">
            <div class="box-modal" style="background:gold;">
                <div class="body">
                    <HolidayEditPopup v-if="holiday_id" :holiday_id="holiday_id" @closeModal="fetchHolidays"
                        :is_editable="is_editable" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HolidayEditPopup from "./HolidayEdit_Popup.vue";

export default {
    name: "Holidays",
    components: {
        HolidayEditPopup
    },
    data() {
        return {
            max: 70,
            max2: 30,
            dispatches: [],
            holidays: [],
            holiday_id: "",
            selectedOption: 'outbound'
        };
    },
    created() {
        this.fetchHolidays();
        // this.fetchModeOfDispatch();
    },
    mounted() {
        this.fetchHolidays();
    },
    methods: {
        fetchHolidays() {
            this.holiday_id = '';
            this.axios.get(`/api/holiday-calender`)
                .then(response => {
                    console.log(response.data.holidays);
                    this.holidays = response.data.holidays;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteHoliday(id) {
            if (confirm("Are you sure you want to delete this Holiday ?")) {
                this.axios
                    .delete("/api/holiday-calender/" + id)
                    .then(response => {
                        this.toast.success(response.data.msg);
                        this.fetchHolidays();
                    })
                    .catch(error => {
                        console.log("error", error.response);
                        this.toast.error();
                    });
            }
        },
        cancelHoliday() {
            this.fetchHolidays();
        }
    }
};
</script>
<style scoped>
.scoped_cover_div {
    margin: 0;
}

@media screen and (max-width: 1600px) {
    .scoped_cover_div {
        padding-right: 20px;
    }
}

.scoped_cover_div section {
    padding: 0;
}

.scoped_cover_div .form_section1 {
    margin-top: 0;
}

.jobs_table.sp2 table tr td {
    padding: 5px 2px;
    vertical-align: top;
}

.jobs_table.sp2 table tr th {
    padding: 5px 10px;
    height: 50px;
}

.table_action_btn {
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align-last: center;
    background: #06A5ED;
    color: #000;
    font-weight: 700;
    display: inline-block;
    margin: 2px;
}

.width_40px {
    width: 80px;
}

.righted {
    text-align: right;
}

.label_and_element_wrapper input[type="text"] {
    padding: 5px 10px;
}

.user_profile_upload {
    display: block;
    border: 0;
    height: 40px;
    width: 150px;
    background: #004a7c;
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
    position: relative;
}

.user_profile_upload input {
    opacity: 0;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.user_profile_upload span {
    display: block;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 30px;
    text-align: center;
    color: #000;
    font-weight: normal;
}

.search-text {
    background-color: #004a7c;
    margin: 0px;
    width: 100%;
    padding: 15px;
    display: flex;

    .category {
        padding: 5px;
        font-size: 1rem;
    }
}

.label_and_element_wrapper img {
    display: block;
    width: 150px;
    height: auto;
}
</style>